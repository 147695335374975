@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.about-work {
    width: 100%;
    background-color: #8B8A86;

    &__center-wrap {
        @include center-wrap;
        position: relative;
        max-width: 80rem;
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include tablet {
            justify-content: space-between;
            align-items: center;
        }

        @include desktop {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__title {
        /*@include page-header;*/
        padding: $mobile-padding-component;
    }

    &__container {
        padding: 0 $mobile-padding-component;
        width: 100%;
    }

    &__item {
        margin-bottom: $mobile-gutter;
        cursor: pointer;
    }

    &__preview-container {
        background-color: $primary-color-black;
        color: white;
        padding: $mobile-padding-component;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.5rem;

        @include tablet {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__preview {
        width: 90%;
        @include body-copy;
        font-weight: 600;

        @include tablet {
            width: auto;
            display: flex;
            align-items: center;
        }
    }

    &__company-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;

        @include tablet {
            padding-left: 0.2rem;
        }
    }

    &__company {
        padding-right: 0.2rem;
    }

    &__preview-logo {
        width: 10%;
        display: flex;
        justify-content: flex-end;

        @include tablet {
            width: auto;
        }
    }

    &__detail-container {
        background-color: #260242;
        color: white;
        padding: $mobile-padding-component;
        margin-bottom: $mobile-padding-component;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.5rem;
    }

    &__location-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: $mobile-padding-component;
    }

    &__location {
        padding-left: 0.2rem;
    }

    &__skill-container {
        gap: 0.4rem;
        display: flex;
        flex-wrap: wrap;
    }

    &__skill {
        padding: 0.4rem;
        background-color: palevioletred;
        border-radius: 0.5rem;
    }

    &__detail-logo {
        color: white;
    }

    &__work {
        @include subheader;
        padding-bottom: $mobile-padding-component
    }

    &__role {
        @include body-copy;
        padding-bottom: $mobile-padding-component
    }

    &__period {
        @include body-copy;
        padding-bottom: $mobile-padding-component
    }

    &__detail-container {
        @include body-copy;
        padding-bottom: $mobile-padding-component;
        display: block;
    }
}