//default font and weight for website

//light
@font-face {
    font-family: 'Inter';
    src:
        url("../../assets/fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
}

//regular
@font-face {
    font-family: 'Inter';
    src:
        url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
}

//demi
@font-face {
    font-family: 'Inter';
    src:
        url("../../assets/fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

//bold
@font-face {
    font-family: 'Inter';
    src:
        url("../../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}