@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.hero {
    width: 100%;
    height: 100vh;
    background-color: #8B8A86;

    @include laptop {
        height: auto;
    }

    &__center-wrap {
        @include center-wrap;
        position: relative;
        max-width: 80rem;
        display: flex;
        flex-wrap: wrap;

        @include tablet {
            justify-content: space-between;
            align-items: center;
        }
    }

    &__image-container {
        height: 100vh;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-color-black;
        opacity: 0.5;
    }

    &__text {
        @include page-header;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
    }
}
