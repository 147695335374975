//breakpoints and spaces for various devices. note: breakpoints are only variables left in px
$mobile-breakpoint: 0px;
$tablet-breakpoint: 768px;
$laptop-breakpoint: 1024px;
$desktop-breakpoint: 1280px;

$mobile-margin: 1rem; //
$mobile-column: auto;
$mobile-gutter: 1rem;
$tablet-margin: 2.25rem;
$tablet-column: auto;
$tablet-gutter: 1.5rem;
$desktop-margin: auto;
//$desktop-margin: 4rem;
$desktop-column: 3.9375rem;
$desktop-gutter: 1.5rem;

$mobile-padding-before-headline: 1.5rem;
$mobile-padding-after-headline: 1rem;
$mobile-padding-component: 1rem; //
$tablet-padding-before-headline: 2.25rem;
$tablet-padding-after-headline: 1.5rem;
$tablet-padding-component: 1.5rem;
