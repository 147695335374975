//remove default settings
*,
*::before,
*::after,
a
 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter';
}

a {
    text-decoration: none;
}
