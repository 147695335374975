@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.article-component {
    position: relative;
    display: inline-block;
    margin-top: $mobile-padding-component;
    width: 100%;
    height: 12rem;

    @include tablet {
        width: 20rem;
        margin-top: 0;
    }

    &__image {
        //@include image;
        object-fit: cover;
        height: 12rem;
        width: 100%;
    }
    
    &__image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #323232;
        opacity: 0.5;

        &--hover {
            opacity: 1;
        }
    }

    &__detail-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        width: 90%;
        //&--hover {
        //    display: none
        //}
    }

    &__description {
        padding-bottom: $mobile-padding-component;
    }

    &__link-image {
        color: white;
    }
}