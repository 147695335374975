@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.about-hobby {
    width: 100%;
    background-color:  #8B8A86;

    &__center-wrap {
        @include center-wrap;
        position: relative;
        max-width: 80rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include desktop {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__title {
        /*@include page-header;*/
        padding: $mobile-padding-component $mobile-padding-component 0 $mobile-padding-component
    }

    &__container {
        padding: $mobile-padding-component;
    }

    &__item {
        @include body-copy;
        padding: $mobile-padding-component;
    }
}