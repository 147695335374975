@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.project-component {
    @include tablet {
        width: 48%;
    }

    @include desktop {
        width: 32%;
    }

    &__image-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 30vh;
        margin: $mobile-padding-component*1.5 0 $mobile-padding-component/2 0;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

    &__image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #323232;
        opacity: 0.5;
        border-radius: 1rem;
    }

    &__title {
        @include subheader;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        width: 100%;
    }

    &__tech-container {
        gap: 0.4rem;
        display: flex;
        flex-wrap: wrap;
    }

    &__tech-item {
        @include body-copy;
        padding: 0.4rem;
        background-color:palevioletred;
        color: $primary-color-white;
        border-radius: 0.5rem;
    }
}