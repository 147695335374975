@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.navigation {
    background-color: $primary-color-black;
    width: 100%;

    &__center-wrap {
        @include center-wrap;
        max-width: 75.5rem;
        display: flex;
        flex-wrap: wrap;

        @include tablet {
            justify-content: space-between;
            align-items: center;
        }
    }

    &__bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $mobile-padding-component $mobile-margin;

        @include tablet {
            padding: $mobile-padding-component $tablet-margin;
        }
    }

    &__logo-container {
        @include tablet {
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__logo-image {
        color: white;
    }

    &__menu-container {
        width: 100%;

        @include tablet {
            width: 30%;
            display: flex;
            flex-wrap: wrap;
        }

        &--mobile {
            @include tablet {
                display: none;
            }
        }

        &--tablet {
            display: none;

            @include tablet {
                display: flex;
                width: 90%;
                align-items: center;
            }
        }
    }

    &__menu-image-container {
        @include tablet {
            display: none;
        }
    }

    &__menu-image {
        color: white;
    }

    &__social-container {
        width: 100%;
        display: flex;
        height: 2rem;
        justify-content: space-around;
        align-items: center;
        border-top: 0.1rem solid $primary-color-black;

        &--tablet {
            display: none;

            @include tablet {
                width: 30%;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

}