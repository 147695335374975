@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.article {
    width: 100%;
    background-color: $primary-color-white;

    &__center-wrap {
        @include center-wrap;
        position: relative;
        max-width: 80rem;

        @include tablet {
            justify-content: space-between;
            align-items: center;
        }
    }

    &__container {
        padding: 0 $mobile-padding-component $mobile-padding-component $mobile-padding-component;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        @include tablet {
            gap: 1rem;
            padding: 0 $mobile-padding-component;
        }
    }

    &__title {
        @include subheader;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: $mobile-padding-component;
        padding-top: $mobile-padding-component*2;

        @include tablet {
            padding-top: $tablet-padding-component;
            padding-bottom: $mobile-padding-component;
        }

        @include desktop {
            padding-top: $tablet-padding-component*2;
            padding-bottom: $mobile-padding-component;
        }
    }

    &__subtitle {
        @include body-copy;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: $mobile-padding-component;
        padding-top: 0;

        @include tablet {
            padding-bottom: $tablet-padding-component;
        }

        @include desktop {
            padding-bottom: $tablet-padding-component*2;
        }
    }
}