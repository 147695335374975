@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.expertise {
    width: 100%;
    color: $primary-color-white;
    background-color: $primary-color-black;

    &__center-wrap {
        @include center-wrap;
        max-width: 80rem;
        display: flex;
        flex-wrap: wrap;
        background-color: $primary-color-black;
        padding: $mobile-padding-component $mobile-margin;
        text-align: center;
    
        @include tablet {
            justify-content: space-between;
            padding: $mobile-padding-component $mobile-margin;
        }
    }

    &__container {
        width: 100%;
        @include tablet {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__title {
        @include subheader;
        width: 100%;
        padding: $mobile-padding-component*1.5 0 $mobile-padding-component*1.5 0;
        text-align: center;
    }

    &__item {
        border: 0.1rem solid $primary-color-white;
        padding: $mobile-padding-component;
        height: 12rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        @include tablet {
            width: 33.33%;
            height: 15rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        margin-right: 1rem;
    }

    &__subtitle {
        @include section-header;
    }

    &__description-container {
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @include tablet {
            height: 6rem;
        }
    }

    &__description-logo-container {
        width: 10%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    &__description-logo--tablet {
        display: none;
        
        @include tablet {
            display: flex;
        }
    }

    &__description {
        @include body-copy;
        width: 80%;

        @include tablet {
            width: 85%;
        }
    }
}