@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.about-bio {
    width: 100%;
    background-image: url('../../assets/images/black-background.jpg');
    background-size: cover;
    color: $primary-color-white;
    //background-color: $primary-color-beige;

    &__center-wrap {
        @include center-wrap;
        position: relative;
        max-width: 80rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__title {
        @include name-page-header;
        padding: 3rem $mobile-padding-component $mobile-padding-before-headline $mobile-padding-component;

        @include tablet {
            padding: $tablet-padding-component;
        }

        @include desktop {
            margin-top: $desktop-gutter
        }
    }

    &__details {
        padding: 0 $mobile-margin;

        @include tablet{    
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 $tablet-margin;
        }

        @include laptop {
            padding: 0 6rem;
        }

        @include desktop {
            margin-bottom: $desktop-gutter
        }
    }

    &__image-container {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;

        @include tablet {
            width: 25%;
        }
    }

    &__image {
        width: 90%;
        height: 90%;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 25%;
        border-bottom-right-radius: 25%;

        @include tablet {
            padding: $mobile-padding-component 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 25%;
            border-top-right-radius: 25%;
            border-bottom-left-radius: 25%;
            border-bottom-right-radius: 25%;
        }
    }

    &__intro-container {
        @include tablet {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__intro-title {
        padding: $mobile-padding-component 0;
        @include subheader;
    }

    &__intro-bio {
        padding-bottom: $mobile-padding-component;
        @include body-copy


    }


}