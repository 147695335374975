@use "../../styles/partials/colors" as *;
@use "../../styles/partials/spacing" as *;
@use "../../styles/partials/spacing-mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/typography-mixins" as *;
@use "../../styles/partials/components" as *;
@use "../../styles/partials/global" as *;

.konnect-image__container {
    background-color: $primary-color-black;
}

.konnect-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 75vh;

    @include laptop {
        width: 49%;
        height: 42rem;
    }

    &__center-wrap {
        @include center-wrap;
        max-width: 80rem;

        @include laptop {
            padding: $mobile-padding-component;
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
        }
    }

    &--horizontal {
        display: none;

        @include laptop {
            display: block;
            width: 50%;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include laptop {
            border-radius: 1rem;
        }
    }

    &__horizontal {
        width: 100%;
        height: 50%;
        border-radius: 1rem;
    }

    &__logo-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #323232;
        opacity: 0.5;

        @include laptop {
            display: none;
        }
    }

    &__title-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        width: 100%;

        @include laptop {
            display: none;
        }
    }

    &__event {
        width: 100%;
    }

    &__title {
        @include name-page-header;
    }

    &__subtitle {
        @include subheader;
    }
}